import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39')
];

export const server_loads = [];

export const dictionary = {
		"/": [9],
		"/(app)/campaign-est-711": [11,[2,3]],
		"/(app)/campaign-est-711/coupons": [12,[2,3]],
		"/(app)/campaign-est-711/use-coupon": [13,[2,3]],
		"/(app)/campaign-platform-est-premium": [16,[2]],
		"/(app)/campaign-platform-est": [15,[2]],
		"/(app)/campaign-platform": [14,[2]],
		"/(app)/campaign": [10,[2]],
		"/(app)/change-phone": [17,[2,4]],
		"/(app)/change-phone/validate": [18,[2,4]],
		"/(app)/est711": [19,[2]],
		"/(external)/ex": [38],
		"/(app)/history-redeem": [21,[2]],
		"/(app)/history": [20,[2]],
		"/(app)/home": [22,[2]],
		"/(app)/liff-test": [23,[2]],
		"/(app)/my-rewards": [25,[2]],
		"/(app)/my-reward": [24,[2]],
		"/(app)/no-liff-init-test": [26,[2,5]],
		"/(app)/no-liff-init-test/child": [27,[2,5]],
		"/(app)/no-liff-init-test/item": [28,[2]],
		"/(app)/profile": [29,[2,6]],
		"/(app)/profile/consents": [30,[2,6]],
		"/(app)/register": [31,[2,7]],
		"/(app)/register/form": [32,[2,7]],
		"/(app)/register/result": [33,[2,7]],
		"/(app)/register/validate-otp": [34,[2,7]],
		"/(app)/request-otp": [35,[2,8]],
		"/(app)/reward": [36,[2]],
		"/sentry-example": [39],
		"/(app)/update-info": [37,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';