import * as Sentry from '@sentry/sveltekit';
// import { sequence } from '@sveltejs/kit/hooks';
import { handleErrorWithSentry } from '@sentry/sveltekit';
// import type { HandleClientError } from '@sveltejs/kit';
import { dev } from '$app/environment';

// Initialize the Sentry SDK here
// only production build
if (!dev) {
	Sentry.init({
		dsn: 'https://888bf12b6c364414b43c7d5d23d53331@o349622.ingest.sentry.io/4505548409077760',

		// We recommend adjusting this value in production, or using tracesSampler
		// for finer control
		tracesSampleRate: 1.0

		// Optional: Initialize Session Replay:
		//   integrations: [new Sentry.Replay()],
		//   replaysSessionSampleRate: 0.1,
		//   replaysOnErrorSampleRate: 1.0,
	});
}

// If you have custom handlers, make sure to place them after `sentryHandle()` in the `sequence` function.
// export const handle = sequence(sentryHandle());

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();

// old style error handling (manual)
// export const handleError = (async ({ error, event }) => {
// 	const errorId = crypto.randomUUID();
// 	// example integration with https://sentry.io/

// 	if (!dev) {
// 		Sentry.captureException(error, { extra: { event, errorId } });
// 	}

// 	return {
// 		message: 'Whoops!',
// 		errorId
// 	};
// }) satisfies HandleClientError;
